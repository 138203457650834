export const darktheme = {
  type: "dark",
  line01: "1px solid #222222", // 暗い灰色
  line02: "1px solid #ffffff", // 白色
  line03: "#000000", // 黒色

  back01: "#000000", // 黒色
  back02: "#ffaaaa", // 淡い赤色（ピンクに近い）
  back03: "#aaaaff", // 淡い青色
  back04: "#333333", // 暗い灰色
  // back05: "#006400", // 暗い緑色
  back05: "#464646",
  // back06: "#DAA520", // 金色
  back06: "#464646",
  back07: "#272831", // 暗い紫灰色
  back08: "#312f2e", // 暗い茶色
  back09: "#3a3c49", // 暗い青灰色
  back10: "#494646", // 暗い茶色
  back11: "#ffffff", // 白色
  back12: "#222222",
  back13: "#e15959", // 赤色
  back14: "#5959e1", // 青色

  opacity01: "rgba(0, 0, 0, 0.7)",

  text01: { fontColor: "#cccccc", fontSize: "130px", fontWeight: "bold" }, // 明るい灰色
  text02: { fontColor: "#ffaaaa", fontSize: "1px", fontWeight: "bold" }, // 淡い赤色（ピンクに近い）
  text03: { fontColor: "#aaaaff", fontSize: "1px", fontWeight: "bold" }, // 淡い青色
  text04: { fontColor: "#ffffff", fontSize: "15px", fontWeight: "bold" }, // 白色
  // text05: { fontColor: "#bfff7f", fontSize: "15px", fontWeight: "bold" }, // 明るい緑色
  text05: { fontColor: "#c6c6c6", fontSize: "15px", fontWeight: "bold" }, // 明るい緑色
  // text06: { fontColor: "#ffff4f", fontSize: "15px", fontWeight: "bold" }, // 明るい黄色
  text06: { fontColor: "#c6c6c6", fontSize: "15px", fontWeight: "bold" }, // 明るい緑色
  text07: { fontColor: "#ffffff", fontSize: "12px", fontWeight: "bold" }, // 白色
  text08: { fontColor: "#999999", fontSize: "130px", fontWeight: "bold" }, // 中間の灰色
  text09: { fontColor: "#555555", fontSize: "130px", fontWeight: "bold" }, // 暗い灰色
  text10: { fontColor: "#333333", fontSize: "130px", fontWeight: "bold" }, // Lonpaカードの中の文字
  text11: { fontColor: "#7777ff", fontSize: "1px", fontWeight: "bold" }, // リンク用の文字
  text12: { fontColor: "#ffff4f", fontSize: "1px", fontWeight: "bold" }, // 説明の強調カラー
  text13: { fontColor: "#ffffff", fontSize: "40px", fontWeight: "bold" }, // 円グラフ文字

  pic01: "url(/lamact/Lamact_white.png)",

  adpic01: "url(/adpic/ad1.jpg)",
  adpic02: "url(/adpic/ad2.jpg)",

  explainpic01: "/explain/explain01.jpg",
  explainpic02: "/explain/explain02.jpg",
};

export const testtheme = {
  type: "light",
  line01: "1px solid #dddddd", // 明るい灰色
  line02: "1px solid #000000", // 黒色
  line03: "#000000", // 黒色

  back01: "#ffffff", // 白色
  back02: "#baf8a8", // 淡い赤色
  back03: "#ffc9f9", // 淡い青色
  back04: "#bbbbbb", // 明るい灰色
  back05: "#bfff7f", // 明るい緑色
  back06: "#ffff4f", // 明るい黄色
  back07: "#EBEEFC", // 淡い紫色
  back08: "#F6EBEA", // 淡いピンク色
  back09: "#C3CAF6", // 淡い青色
  back10: "#EACDCC", // ベージュ色
  back11: "#000000", // 黒色
  back12: "#eeeeee",
  back13: "#ff7777", // 淡い赤色
  back14: "#7777ff", // 淡い青色

  opacity01: "rgba(255, 255, 255, 0.7)",

  text01: { fontColor: "#777777", fontSize: "130px", fontWeight: "bold" }, // 暗い灰色
  text02: { fontColor: "#ff7777", fontSize: "1px", fontWeight: "bold" }, // 淡い赤色
  text03: { fontColor: "#7777ff", fontSize: "1px", fontWeight: "bold" }, // 淡い青色
  text04: { fontColor: "#000000", fontSize: "15px", fontWeight: "bold" }, // 黒色
  text05: { fontColor: "#006400", fontSize: "15px", fontWeight: "bold" }, // 暗い緑色
  text06: { fontColor: "#DAA520", fontSize: "15px", fontWeight: "bold" }, // 金色
  text07: { fontColor: "#000000", fontSize: "12px", fontWeight: "bold" }, // 黒色
  text08: { fontColor: "#666666", fontSize: "130px", fontWeight: "bold" }, // 暗い灰色
  text09: { fontColor: "#aaaaaa", fontSize: "130px", fontWeight: "bold" }, // 中間の灰色
  text10: { fontColor: "#444444", fontSize: "130px", fontWeight: "bold" }, // Lonpaカードの中の文字
  text11: { fontColor: "#2222ff", fontSize: "1px", fontWeight: "bold" }, // リンク用の文字
  text12: { fontColor: "#069edc", fontSize: "1px", fontWeight: "bold" }, // 説明の強調カラー
  text13: { fontColor: "#000000", fontSize: "40px", fontWeight: "bold" }, // 円グラフ文字

  pic01: "url(/lamact/Lamact.png)",

  adpic01: "url(/adpic/ad1_white.jpg)",
  adpic02: "url(/adpic/ad2_white.jpg)",

  explainpic01: "/explain/explain01_white.jpg",
  explainpic02: "/explain/explain02_white.jpg",
};

export const lighttheme = {
  type: "light",
  line01: "1px solid #dddddd", // 明るい灰色
  line02: "1px solid #000000", // 黒色
  line03: "#000000", // 黒色

  back01: "#ffffff", // 白色
  back02: "#919191", // 淡い灰色
  back03: "#464646", // 濃い灰色

  back04: "#bbbbbb", // 明るい灰色
  // back05: "#bfff7f", // 明るい緑色
  back05: "#e2e2e2",
  // back06: "#ffff4f", // 明るい黄色
  back06: "#e2e2e2",
  back07: "#EBEEFC", // 淡い紫色
  back08: "#F6EBEA", // 淡いピンク色
  back09: "#C3CAF6", // 淡い青色
  back10: "#EACDCC", // ベージュ色
  back11: "#000000", // 黒色
  back12: "#eeeeee",
  back13: "#ff7777", // 淡い赤色
  back14: "#7777ff", // 淡い青色

  opacity01: "rgba(255, 255, 255, 0.7)",

  text01: { fontColor: "#555555", fontSize: "130px", fontWeight: "bold" }, // 暗い灰色
  text02: { fontColor: "#ff7777", fontSize: "1px", fontWeight: "bold" }, // 淡い赤色
  text03: { fontColor: "#7777ff", fontSize: "1px", fontWeight: "bold" }, // 淡い青色
  text04: { fontColor: "#000000", fontSize: "15px", fontWeight: "bold" }, // 黒色
  // text05: { fontColor: "#006400", fontSize: "15px", fontWeight: "bold" }, // 暗い緑色
  text05: { fontColor: "#464646", fontSize: "15px", fontWeight: "bold" }, //
  // text06: { fontColor: "#DAA520", fontSize: "15px", fontWeight: "bold" }, // 金色
  text06: { fontColor: "#464646", fontSize: "15px", fontWeight: "bold" }, //
  text07: { fontColor: "#000000", fontSize: "12px", fontWeight: "bold" }, // 黒色
  text08: { fontColor: "#666666", fontSize: "130px", fontWeight: "bold" }, // 暗い灰色
  text09: { fontColor: "#aaaaaa", fontSize: "130px", fontWeight: "bold" }, // 中間の灰色
  text10: { fontColor: "#444444", fontSize: "130px", fontWeight: "bold" }, // Lonpaカードの中の文字
  text11: { fontColor: "#2222ff", fontSize: "1px", fontWeight: "bold" }, // リンク用の文字
  text12: { fontColor: "#069edc", fontSize: "1px", fontWeight: "bold" }, // 説明の強調カラー
  text13: { fontColor: "#000000", fontSize: "40px", fontWeight: "bold" }, // 円グラフ文字

  pic01: "url(/lamact/Lamact.png)",

  adpic01: "url(/adpic/ad1_white.jpg)",
  adpic02: "url(/adpic/ad2_white.jpg)",

  explainpic01: "/explain/explain01_white.jpg",
  explainpic02: "/explain/explain02_white.jpg",
};

export const testtheme2 = {
  type: "light",
  line01: "1px solid #dddddd", // 明るい灰色
  line02: "1px solid #000000", // 黒色
  line03: "#000000", // 黒色

  back01: "#ffffff", // 白色
  back02: "#F9C8C3", // 淡い赤色
  back03: "#C9F1FF", // 淡い青色
  back04: "#bbbbbb", // 明るい灰色
  back05: "#bfff7f", // 明るい緑色
  back06: "#ffff4f", // 明るい黄色
  back07: "#EBEEFC", // 淡い紫色
  back08: "#F6EBEA", // 淡いピンク色
  back09: "#C3CAF6", // 淡い青色
  back10: "#EACDCC", // ベージュ色
  back11: "#000000", // 黒色
  back12: "#eeeeee",
  back13: "#ff7777", // 淡い赤色
  back14: "#7777ff", // 淡い青色

  opacity01: "rgba(255, 255, 255, 0.7)",

  text01: { fontColor: "#777777", fontSize: "130px", fontWeight: "bold" }, // 暗い灰色
  text02: { fontColor: "#ff7777", fontSize: "1px", fontWeight: "bold" }, // 淡い赤色
  text03: { fontColor: "#7777ff", fontSize: "1px", fontWeight: "bold" }, // 淡い青色
  text04: { fontColor: "#000000", fontSize: "15px", fontWeight: "bold" }, // 黒色
  text05: { fontColor: "#006400", fontSize: "15px", fontWeight: "bold" }, // 暗い緑色
  text06: { fontColor: "#DAA520", fontSize: "15px", fontWeight: "bold" }, // 金色
  text07: { fontColor: "#000000", fontSize: "12px", fontWeight: "bold" }, // 黒色
  text08: { fontColor: "#666666", fontSize: "130px", fontWeight: "bold" }, // 暗い灰色
  text09: { fontColor: "#aaaaaa", fontSize: "130px", fontWeight: "bold" }, // 中間の灰色
  text10: { fontColor: "#444444", fontSize: "130px", fontWeight: "bold" }, // Lonpaカードの中の文字
  text11: { fontColor: "#2222ff", fontSize: "1px", fontWeight: "bold" }, // リンク用の文字
  text12: { fontColor: "#069edc", fontSize: "1px", fontWeight: "bold" }, // 説明の強調カラー
  text13: { fontColor: "#000000", fontSize: "40px", fontWeight: "bold" }, // 円グラフ文字

  pic01: "url(/lamact/Lamact.png)",

  adpic01: "url(/adpic/ad1_white.jpg)",
  adpic02: "url(/adpic/ad2_white.jpg)",

  explainpic01: "/explain/explain01_white.jpg",
  explainpic02: "/explain/explain02_white.jpg",
};
