import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
  IconButton,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";

// import talkerpic2 from "../../images/talker/lakane.png";
// import talkerpic1 from "../../images/talker/laoi.png";

// eslint-disable-next-line import/no-unresolved
import talkerpic2 from "../../images/talker/a.gif";
// eslint-disable-next-line import/no-unresolved
import talkerpic1 from "../../images/talker/b.gif";
import Message from "../Chat/Message";

type Props = {
  bgColor: any;
  styletext: any;
  info: any;
  messageReflesh: number;
};

const TStage1: React.VFC<Props> = ({
  bgColor,
  styletext,
  info,
  messageReflesh,
}: Props) => {
  // const RedButton = {
  //   fontSize: "20px",
  //   color: bgColor.text01.fontColor,
  //   width: styletext.windowWidth * 0.45,
  //   height: "50px",
  //   fontWeight: "bold",
  //   // border: bgColor.line01,
  //   backgroundColor: bgColor.back02,
  // };

  // const BlueButton = {
  //   fontSize: "20px",
  //   color: bgColor.text01.fontColor,
  //   width: styletext.windowWidth * 0.45,
  //   height: "50px",
  //   fontWeight: "bold",
  //   // border: bgColor.line01,
  //   backgroundColor: bgColor.back03,
  // };

  return (
    <>
      <div
        style={{
          zIndex: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "126px",
        }}
      >
        <Message
          bgColor={bgColor}
          styletext={styletext}
          info={info}
          messageReflesh={messageReflesh}
        />
      </div>
      <div
        style={{
          // width: "10px",
          zIndex: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <table style={{ width: styletext.windowPhoneWidth }}>
          <tbody>
            <tr>
              <td
                style={{
                  position: "relative",
                  width: styletext.windowPhoneWidth * 0.45,
                }}
              >
                <img
                  style={{
                    width: "100%", // この変更により、画像はtdの幅に合わせて表示されます
                  }}
                  src={talkerpic1}
                  alt="img"
                />
              </td>
              <td style={{ width: styletext.windowPhoneWidth * 0.1 }}></td>
              <td
                style={{
                  position: "relative",
                  width: styletext.windowPhoneWidth * 0.45,
                }}
              >
                <img
                  style={{
                    width: "100%", // この変更により、画像はtdの幅に合わせて表示されます
                  }}
                  src={talkerpic2}
                  alt="loading..."
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default TStage1;
