import { Container, Grid, Button, Link } from "@mui/material";
import { serverTimestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

import {
  getDiscuss,
  getRandomDiscussId,
  getDiscussById,
} from "../common/Helper/FirebaseHelper";
// import talkerpic2 from "../images/talker/lakane.png";
// import talkerpic4 from "../images/talker/lakane2.png";
// import talkerpic1 from "../images/talker/laoi.png";
// import talkerpic3 from "../images/talker/laoi2.png";
import talkerpic2 from "../images/talker/a.gif";
import talkerpic4 from "../images/talker/a.png";
import talkerpic1 from "../images/talker/b.gif";
import talkerpic3 from "../images/talker/b.png";

import PieChart from "./Discuss/PieChart";
import Stage1 from "./Discuss/Stage1";
import Stage2 from "./Discuss/Stage2";
import Stage25 from "./Discuss/Stage25";
import Stage3 from "./Discuss/Stage3";
import { pushLon } from "./Discuss/UpdateVote";

type Props = {
  bgColor: any;
  styletext: any;
};

type Params = {
  lonpaid: string; // パラメータ`lonpaid`が文字列型であることを定義
};

const Discuss: React.VFC<Props> = ({ bgColor, styletext }: Props) => {
  const { lonpaid } = useParams<Params>();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([]);

  const [isStage1Finish, setIsStage1Finish] = useState(false);
  const [isStage2Finish, setIsStage2Finish] = useState(false);
  const [isStage25Finish, setIsStage25Finish] = useState(true);
  const [isBlue, setIsBlue] = useState(true);

  const [opacity, setOpacity] = useState(0);

  const [messageReflesh, setMessageReflesh] = useState(0);

  const [info, setInfo] = useState({
    id: "loading",
    isLoding: true,
    claim: "",
    messageLine: [
      { index: 1, text: "", vocal: "Bob", isAgree: false },
      { index: 2, text: "", vocal: "Bob", isAgree: false },
    ],
    infoagree: [{ votenum: 0, agree: true, claim: "", isDelete: false }],
    infodisagree: [{ votenum: 0, agree: false, claim: "", isDelete: false }],
    disagreeratio: NaN,
    agree: 0,
    disagree: 0,
  });

  const [choice, setChoice] = useState({
    choiceText: "",
    sameAgree: 0,
    sameOpinion: 0,
    talkText: "",
  });

  // styletext.windowWidth
  const width02 = styletext.windowPhoneWidth * 0.55;
  const thisHeight = styletext.windowPhoneWidth * (0.55 + 0.45 + 0.2) + 26;

  useEffect(() => {
    console.log("uE_Discuss");
    const f = async () => {
      try {
        // const one = await getDiscussById(nextnum);
        // const onedata = one?.data();
        // console.log(onedata);

        //新着のデータ取得
        lonpaid && (await UpdateDate(lonpaid));
        const timer = setTimeout(() => {
          setOpacity(1);
        }, 20); // コンポーネントのマウント直後にアニメーション開始

        return () => {
          clearTimeout(timer);
        };
      } catch (e) {
        console.log("error");
        console.log(e);
        alert("データ取得に失敗しました");
        navigate("/Sorry");
      }
    };
    f();
  }, []);

  useEffect(() => {
    console.log("uE_Discuss_choice");
    const f = async () => {
      try {
        const sameAgree = isBlue ? info.agree : info.disagree;
        let talkText = "";

        if (isBlue && info.agree > info.disagree) {
          talkText = "賛成多数だね、負けました";
        } else if (!isBlue && info.agree < info.disagree) {
          talkText = "反対多数か、負けました";
        } else {
          talkText = "少数意見だけど確かに納得";
        }

        setChoice({
          ...choice,
          sameAgree: sameAgree,
          talkText: talkText,
        });
      } catch (e) {
        console.log("error");
        console.log(e);
      }
    };
    f();
  }, [isStage2Finish]);

  async function UpdateDate(nextlonpaid: string) {
    let doc: any;
    // lonpaid が 'discussID:' で始まるかどうか
    //その場合はリンクで飛んできている
    if (nextlonpaid?.startsWith("discussID:")) {
      const remainingPart = parseInt(
        nextlonpaid.slice("discussID:".length),
        10
      );
      // console.log(remainingPart)
      doc = await getDiscussById(remainingPart);
      // console.log(doc);
    } else {
      // 'discussID:' で始まらない場合の処理が必要であればここに記述
      doc = await getDiscuss(nextlonpaid);
    }

    // docがnullまたはundefinedではないことを確認
    if (!doc) {
      console.error("Document not found");
      return; // またはエラーを処理するための適切な方法を選択
    }

    // doc.data()がnullまたはundefinedではないこと、またdata内にclaimが存在することを確認
    const data = doc.data();
    if (!data || typeof data.claim === "undefined") {
      console.error("Claim data is missing or undefined");
      return; // またはエラーを処理するための適切な方法を選択
    }
    const infoClaim = data.claim;
    const messageLine = data.genko;
    // const agreeChoice = data.infoagree.map((item: any) => item.data.claim);
    // const disagreeChoice = data.infodisagree.map(
    //   (item: any) => item.data.claim
    // );

    let disagreeratio = NaN;
    if (data.agree === 0) {
      if (data.disagree === 0) {
        disagreeratio = NaN;
      } else {
        disagreeratio = 0;
      }
    }
    if (data.disagree === 0) {
      disagreeratio = 100;
    } else {
      disagreeratio = (data.disagree / (data.agree + data.disagree)) * 100;
    }

    setInfo({
      id: doc?.id ?? "undefined",
      isLoding: false,
      claim: infoClaim,
      messageLine: messageLine,
      infoagree: data.infoagree,
      infodisagree: data.infodisagree,
      disagreeratio: disagreeratio,
      agree: data.agree,
      disagree: data.disagree,
    });

    // console.log(info);
  }

  const handlers = useSwipeable({
    onSwipedUp: async () => {
      // 下にスワイプされたときの処理
      // console.log('swipe')
      if (!isStage1Finish) {
        setInfo({
          id: "loading",
          isLoding: true,
          claim: "",
          messageLine: [
            { index: 1, text: "", vocal: "Bob", isAgree: false },
            { index: 2, text: "", vocal: "Bob", isAgree: false },
          ],
          infoagree: [{ votenum: 0, agree: true, claim: "", isDelete: false }],
          infodisagree: [
            { votenum: 0, agree: false, claim: "", isDelete: false },
          ],
          disagreeratio: NaN,
          agree: 0,
          disagree: 0,
        });
        setOpacity(0);
        setMessageReflesh(messageReflesh + 1);

        const nextnum = await getRandomDiscussId();
        navigate("/Discuss/discussID:" + nextnum);
        await UpdateDate("discussID:" + nextnum);

        window.scrollTo(0, 0);

        setOpacity(1);
      }
    },
  });

  async function UpdateVote(item: any) {
    pushLon(
      item,
      true,
      lonpaid ?? "nodata",
      serverTimestamp(),
      info.claim,
      cookies,
      setCookie,
      removeCookie
    );
  }

  const combinedStyles: React.CSSProperties = {
    transition: "opacity 0.5s ease-in-out",
    opacity: opacity,
  };

  // 方眼紙のスタイルを定義
  const gridStyle = {
    backgroundImage: `
            linear-gradient(to right,  rgba(211, 211, 211, 0.5) 1px, transparent 1px),
            linear-gradient(to bottom,  rgba(211, 211, 211, 0.5) 1px, transparent 1px)
          `,
    backgroundSize: "10px 10px",
    width: styletext.windowPhoneWidth,
  };

  const markerUnderlineStyle = {
    display: "inline",
    backgroundImage:
      "linear-gradient(120deg, rgba(255, 255, 0, 0.5) 0%, rgba(255, 255, 0, 0.5) 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "80% 0.7em",
    backgroundPosition: "50% 90%",
  };

  return (
    <div
      style={{
        backgroundColor: bgColor.back01,
        height: styletext.windowHeight - 60,
      }}
    >
      <div style={{ height: "3vh" }}></div>
      <div
        style={{
          height: thisHeight,
        }}
      >
        <div
          {...handlers}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            marginLeft: "auto", // 左のマージンを自動に設定
            marginRight: "auto", // 右のマージンを自動に設定
            ...gridStyle,
          }}
        >
          <div
            style={{
              ...combinedStyles,
            }}
          >
            <br />
            {/* 円グラフ */}
            <PieChart
              bgColor={bgColor}
              styletext={styletext}
              disagreeratio={info.disagreeratio}
            />

            <div
              style={{
                display: "flex", // Flexboxを有効にする
                alignItems: "center", // 子要素を垂直方向の中央に揃える
                height: width02,
                justifyContent: "center", // 子要素を水平方向の中央に揃える（必要に応じて）
                zIndex: 3,
              }}
            >
              <div
                style={{
                  ...markerUnderlineStyle,
                  position: "relative",
                  width: styletext.windowPhoneWidth,
                  textAlign: "center",
                  color: bgColor.text13.fontColor,
                  fontSize: bgColor.text13.fontSize,
                  marginLeft: "10px", // 左のマージン
                  marginRight: "10px", // 右のマージン
                }}
              >
                {info.claim != "" ? <>{info.claim}?</> : <></>}
              </div>
            </div>
          </div>

          {isStage25Finish ? (
            <>
              {isStage2Finish ? (
                <>
                  {" "}
                  <Stage3
                    bgColor={bgColor}
                    styletext={styletext}
                    setIsStage2Finish={setIsStage2Finish}
                    setIsStage1Finish={setIsStage1Finish}
                    isBlue={isBlue}
                    info={info}
                    choice={choice}
                    talkerpic1={talkerpic1}
                    talkerpic2={talkerpic2}
                  />
                </>
              ) : (
                <>
                  {isStage1Finish ? (
                    <>
                      <Stage2
                        bgColor={bgColor}
                        styletext={styletext}
                        setIsStage2Finish={setIsStage2Finish}
                        setIsStage1Finish={setIsStage1Finish}
                        setIsStage25Finish={setIsStage25Finish}
                        isBlue={isBlue}
                        info={info}
                        setChoice={setChoice}
                        talkerpic1={talkerpic3}
                        talkerpic2={talkerpic4}
                        UpdateVote={UpdateVote}
                      />
                    </>
                  ) : (
                    <>
                      <Stage1
                        bgColor={bgColor}
                        styletext={styletext}
                        setIsStage1Finish={setIsStage1Finish}
                        setIsBlue={setIsBlue}
                        info={info}
                        talkerpic1={talkerpic1}
                        talkerpic2={talkerpic2}
                        talkerpic3={talkerpic3}
                        talkerpic4={talkerpic4}
                        messageReflesh={messageReflesh}
                      />
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Stage25
                bgColor={bgColor}
                styletext={styletext}
                setIsStage2Finish={setIsStage2Finish}
                setIsStage1Finish={setIsStage1Finish}
                setIsStage25Finish={setIsStage25Finish}
                isBlue={isBlue}
                info={info}
                setChoice={setChoice}
                talkerpic1={talkerpic3}
                talkerpic2={talkerpic4}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Discuss;
